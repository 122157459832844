//import { connectors } from 'web3modal';
import WalletConnectProvider from '@walletconnect/web3-provider';
//Icons
//import BinanceIcon from '../assets/img/binance-wallet.svg'
//import MathIcon from '../assets/img/math-wallet.svg'
//import TrustIcon from '../assets/img/trust-wallet.svg'
//import SafepalIcon from '../assets/img/safepal-wallet.svg'
// import BinanceIcon from '../assets/img/binance-wallet.png'

export const providers = {
  walletconnect: {
    package: WalletConnectProvider,
    options: {
      rpc: {
        1:  'https://data-seed-prebsc-1-s2.binance.org:8545/',
        56: 'https://data-seed-prebsc-1-s2.binance.org:8545/', 
        97: 'https://data-seed-prebsc-1-s2.binance.org:8545/', 
      },
    },
  },
};
