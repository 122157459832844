import { createContext, useState } from "react";
const HistoryModalContext = createContext({
  showModal: function () {},
  hideModal: function () {},
});

export const HistoryModalContextProvider = (props) => {
  const [activeModal, setActiveModal] = useState(false);

  const handleShowModal = () => {
    setActiveModal(true);
  };

  const handleHideModal = () => {
    setActiveModal(false);
  };
  const context = {
    isHistoryModalOpen: activeModal,
    showModal: handleShowModal,
    hideModal: handleHideModal,
  };
  return (
    <HistoryModalContext.Provider value={context}>
      {props.children}
    </HistoryModalContext.Provider>
  );
};

export default HistoryModalContext;
