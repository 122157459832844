import { BEPAVA } from "./abi";

export const networks = {

  /*
  bepbusd: {
    contract: "0xdc5c22ce4ae58e74ed9db9d20f5f6f5ab167551b", 
    TokenContract:"0xD6BEB6CDaB0F23e0663A9aA1D201A3bf10e34eFE",
    chainId: "97",
    color: "0B32CC",
    projectId:"44",
    bonusamount:0,
    displaydecimals:4,
    fullName: "Binance-Peg BUSD Token",
    ABI: BEPAVA,
    mindeposit:10,
    maxdeposit:250,
    title: "bepbusd",
    logoname: "busd.png",
    rpcURl: "https://data-seed-prebsc-1-s1.binance.org:8545/",
    explorerlink: [{address:"0xdc5c22ce4ae58e74ed9db9d20f5f6f5ab167551b",link:"https://bscscan.com/address/0xdc5c22ce4ae58e74ed9db9d20f5f6f5ab167551b"}],
    blockchain: "Binance Smart Chain",
    type: "2",
    tokenId: "binance-usd",
    unit: "BUSD",
    TokenDecimals:18,
    explorer:"https://testnet.bscscan.com/tx/",
    networkData: [
      {
        chainId: "0x61",
        chainName: "BSC TestNet",
        rpcUrls: ["https://data-seed-prebsc-1-s1.binance.org:8545/"],
        nativeCurrency: {
          name: "BINANCE COIN",
          symbol: "BNB",
          decimals: 18,
        },
        blockExplorerUrls: ["https://testnet.bscscan.com/"],
      },
    ],
  },
  */ 
  bepbusd: {
    contract: "0xeef6a3ae252b82f8d756115a77dfb0326de379d4", 
    TokenContract:"0x55d398326f99059fF775485246999027B3197955",
    chainId: "56",
    color: "0B32CC",
    projectId:"44",
    bonusamount:0,
    displaydecimals:4,
    fullName: "Binance-Peg BUSD Token",
    ABI: BEPAVA,
    mindeposit:10,
    maxdeposit:250,
    minsell:5,
    title: "bepbusd",
    logoname: "busd.png",
    rpcURl: "https://bscrpc.com",
    explorerlink: [{address:"0xeef6a3ae252b82f8d756115a77dfb0326de379d4",link:"https://bscscan.com/address/0xeef6a3ae252b82f8d756115a77dfb0326de379d4"}],
    blockchain: "Binance Smart Chain",
    type: "2",
    tokenId: "binance-usd",
    unit: "BUSD",
    tokenunit: "LEO",
    TokenDecimals:18,
    explorer:"https://bscscan.com/tx/",
    networkData: [
      {
        chainId: "0x38",
        chainName: "BSC MainNet",
        rpcUrls: ["https://bscrpc.com"],
        nativeCurrency: {
          name: "BINANCE COIN",
          symbol: "BNB",
          decimals: 18,
        },
        blockExplorerUrls: ["https://bscscan.com/"],
      },
    ],
  }
};

export const networkschange = {
  eth: "eth",
  bsc: "bsc",
  polygon: "polygon",
  trx: "trx",
  usdt: "usdt",
  bepava:"bepava",
};
