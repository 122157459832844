import {useEffect} from 'react'
import { Route, Switch, BrowserRouter } from "react-router-dom";
import HomeScreen from "./components/HomeScreen";
// import FooterSection from "./components/FooterSection";

const App = () => {
  function getParameterByName(name, url = window.location.href) {
    name = name.replace(/[\[\]]/g, '\\$&');
    var regex = new RegExp('[?&]' + name + '(=([^&#]*)|&|#|$)'),
        results = regex.exec(url);
    if (!results) return null;
    if (!results[2]) return '';
    return decodeURIComponent(results[2].replace(/\+/g, ' '));
}
  useEffect(() => {
    if (window.location.href.includes('?ref=')) {
      let getAddress = getParameterByName('ref');
      let final = getAddress;
      localStorage.setItem('baseRef', final);
  }
  }, [])

  return (
    <>
      <BrowserRouter>
        <Switch>
          <Route path="/" exact component={HomeScreen} />

        </Switch>
        {/* <FooterSection /> */}
      </BrowserRouter>
    </>
  );
};

export default App;
