export const formatTimeStamp = (timestamp) => {
  const EventTime = new Date(timestamp * 1000).toLocaleTimeString([], {
    hourCycle: "h23",
  });
  const eventDate = new Date(timestamp * 1000).toLocaleDateString().split("/");
  const finalDate = [
    eventDate[2],
    ("0" + eventDate[0]).slice(-2),
    ("0" + eventDate[1]).slice(-2),
  ].join("/");
  return [finalDate," ", EventTime];
};

export const timeDifference = (timestamp) => {
  const msPerMinute = 60 * 1000;
  const msPerHour = msPerMinute * 60;
  const msPerDay = msPerHour * 24;
  const msPerMonth = msPerDay * 30;
  const msPerYear = msPerDay * 365;
  const current = new Date();
  const previous = timestamp * 1000;

  var elapsed = current - previous;

  if (elapsed < msPerMinute) {
    return Math.round(elapsed / 1000) + " seconds ago";
  } else if (elapsed < msPerHour) {
    return Math.round(elapsed / msPerMinute) + " minutes ago";
  } else if (elapsed < msPerDay) {
    return Math.round(elapsed / msPerHour) + " hours ago";
  } else if (elapsed < msPerMonth) {
    return "approximately " + Math.round(elapsed / msPerDay) + " days ago";
  } else if (elapsed < msPerYear) {
    return "approximately " + Math.round(elapsed / msPerMonth) + " months ago";
  } else {
    return "approximately " + Math.round(elapsed / msPerYear) + " years ago";
  }
};
export const getvaluebetween = (min,max,decimal) => {
    var precision=1;
    for(var i=0;i<decimal;i++)
        precision=precision*10;
    max=max*precision;
    min=min*precision;
    return Math.floor(Math.random()*(max-min+1)+min)/precision;
};


export const walletAddressSlicer = (wallet) => {
  if (typeof wallet === "string") {
    return (
      wallet.substring(0, 5) +
      "..." +
      wallet.substring(wallet.length - 5, wallet.length)
    );
  }
};

export const walletAddressSlicerchanged = (wallet) => {
  if (typeof wallet === "string") {
    return (
      wallet.substring(0, 35) +"..." 
    );
  }
};

export const numberWithCommas = (number, tofixed) => {
    if(number ==null)
        return null;
    if (tofixed!=null)
        //number = Number(number).toFixed(tofixed);
      number = getnumberfixeddecimal(number,tofixed)

    const str = number.toString().split('.');
    str[0] = str[0].replace(/(\d)(?=(\d{3})+$)/g, '$1');

    return str.join('.');
}

export const removeexponentials = (n) => {
    n = (n + "").toLowerCase();
    var sign = +n < 0 ? "-" : "",
      toStr = n.toString();
    if (!/e/i.test(toStr)) {
      return n;
    }
    var [lead, decimal, pow] = n.toString().replace(/^-/, "").replace(/^([0-9]+)(e.*)/, "$1.$2").split(/e|\./);
    return +pow < 0 ?
      sign + "0." + "0".repeat(Math.max(Math.abs(pow) - 1 || 0, 0)) + lead + decimal :
      sign + lead + (+pow >= decimal.length ? (decimal + "0".repeat(Math.max(+pow - decimal.length || 0, 0))) : (decimal.slice(0, +pow) + "." + decimal.slice(+pow)))

  }

export const getnumberfixeddecimal = (number, decimals, nodots = false) => {
  if (isNaN(Number(number))) {
    return number;
  } else if (decimals < 16) {
    return removeexponentials(truncateToDecimals(number, decimals));
  } else if (nodots) {
    return removeexponentials(truncateToDecimals(number, decimals));
  } else {
    number = removeexponentials(number);
    return (
      (number + "").substring(0, 4) +
      "..." +
      (number + "").substring((number + "").length - 3)
    );
  }
};
export const truncateToDecimals =  (num, fixed = 0) => {
   num = removeexponentials(num);
   var re = new RegExp("^-?\\d+(?:.\\d{0," + (fixed || -1) + "})?");
   return num.toString().match(re)[0];
 };
  
  