

import HomePageMiddle from "./home/HomePageMiddle"
const HomeScreen = () => {
  return (
    <>
    <HomePageMiddle />
    </>
  )
}

export default HomeScreen 